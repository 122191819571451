import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import { FC, memo, useMemo } from "react";

import Menu from "@mui/icons-material/Menu";
import Receipt from "@mui/icons-material/Receipt";
import Phone from "@mui/icons-material/Phone";
import Directions from "@mui/icons-material/Directions";

import { Link } from "gatsby-theme-material-ui";
import { useLocaleContext } from "../contexts/LocaleContext";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const translations = {
  bg: {
    title: "Вила Вили Ла Коста",
    inquiry: "Направете Запитване",
    inquiry_short: "Запитване",
    call_now: "Обадете Се Сега",
    call_now_short: "Обаждане",
    download_geo_instruction: "Изтегляне На Упътвания",
    download_geo_instruction_short: "Упътване",
  },
  en: {
    title: "Villa Vili La Costa",
    inquiry: "Make an Inquiry",
    call_now: "Call us now",
    download_geo_instruction: "Download Instructions",
  },
};

const getMenuActions = (locale) => [
  {
    label: translations[locale].inquiry,
    label_short: translations[locale].inquiry_short,
    Icon: Receipt,
    to: "#contact",
    onClick: () => {
      window?.gtag?.("event", "navigation_request_form", { value: 1 });
    },
  },
  {
    label: translations[locale].call_now,
    label_short: translations[locale].call_now_short,
    href: "tel:+359-88-586-0719",
    target: "_blank",
    Icon: Phone,
    onClick: () => {
      window?.gtag?.("event", "navigation_request_call_us", { value: 1 });
    },
  },
  {
    label: translations[locale].download_geo_instruction,
    label_short: translations[locale].download_geo_instruction_short,
    href: "https://www.google.com/maps/dir//%D0%92%D0%B8%D0%BB%D0%B0+%D0%92%D0%B8%D0%BB%D0%B8+%D0%9B%D0%B0+%D0%9A%D0%BE%D1%81%D1%82%D0%B0/data=!4m8!4m7!1m0!1m5!1m1!1s0x40a12d05008c7775:0x893405732f4d8486!2m2!1d27.802868!2d42.211911",
    target: "_blank",
    Icon: Directions,
    onClick: () => {
      window?.gtag?.("event", "navigation_request_directions", { value: 1 });
    },
  },
];

export const TopBar: FC = memo(() => {
  const locale = useLocaleContext();
  const scroll = useScrollTrigger({ disableHysteresis: true, threshold: 0 });
  const menuActions = useMemo(() => getMenuActions(locale), [locale]);
  return (
    <>
      <AppBar
        color={scroll ? "inherit" : "transparent"}
        elevation={scroll ? 1 : 0}
        sx={{
          transition: "background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        }}
      >
        <Toolbar>
          <IconButton color="primary">
            <Menu aria-label="Menu button" />
          </IconButton>
          <Link to={"/"}>
            <Typography color="primary" variant="h5" noWrap>
              {translations[locale].title}
            </Typography>
          </Link>
          <Box flex={1} />
          <Hidden mdDown implementation="css">
            <Box display="flex" alignItems="center">
              {menuActions.map(({ Icon, label, ...rest }) => (
                <>
                  <Icon color="primary" sx={{ ml: 5 }} />
                  <Link sx={{ ml: 1 }} noWrap {...rest}>
                    {label}
                  </Link>
                </>
              ))}
            </Box>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Hidden mdUp implementation="css">
        <AppBar
          position="fixed"
          color="inherit"
          sx={{ top: "auto", bottom: 0 }}
        >
          <Toolbar sx={{ pt: 1, pb: 1 }}>
            {menuActions.map(({ Icon, label_short, ...rest }) => (
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Icon color="primary" />
                {rest.href && (
                  <OutboundLink {...rest} style={{ color: "#ffb300" }}>
                    {label_short}
                  </OutboundLink>
                )}
                {rest.to && (
                  <Link sx={{ ml: 1 }} noWrap {...rest}>
                    {label_short}
                  </Link>
                )}
              </Box>
            ))}
          </Toolbar>
        </AppBar>
      </Hidden>
    </>
  );
});
