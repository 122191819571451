import {
  createContext,
  FC,
  useContext,
  useState,
  PropsWithChildren,
} from "react";

type LOCALE = "bg" | "en";

const LocaleContext = createContext<LOCALE | undefined>(undefined);

export const useLocaleContext = () => {
  const context = useContext(LocaleContext);
  if (context === undefined) {
    throw new Error(
      `${useLocaleContext.name} must be used within a ${LocaleContextProvider.name}`
    );
  }
  return context;
};

export const LocaleContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [locale] = useState<LOCALE>("bg");
  return (
    <LocaleContext.Provider value={locale}>{children}</LocaleContext.Provider>
  );
};
