import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import { TopBar } from "../../components/TopBar";
import { Box, responsiveFontSizes } from "@mui/material";
import { LocaleContextProvider } from "../../contexts/LocaleContext";
import { amber } from "@mui/material/colors";
import { FC, PropsWithChildren } from "react";
import { createTheme } from "@mui/material/styles";
import { CustomerChat } from "../../components/FbChat";

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: "light",
      primary: { main: amber[600] },
      secondary: { main: "#2196f3" },
    },
    shape: { borderRadius: 8 },
  })
);

const TopLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ThemeTopLayout theme={theme}>
      <LocaleContextProvider>
        <TopBar />
        <Box sx={{ mb: 10 }}>{children}</Box>
        <CustomerChat />
      </LocaleContextProvider>
    </ThemeTopLayout>
  );
};

export default TopLayout;
